import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import MediaBanner from '../components/MediaBanner';
import ModularBlocks from '../components/ModularBlocks';
import { SiteContext } from '../context/SiteContext';
import { getAnchorId } from '../utils/AnchorId';

const ProductServicePageTemplate = ({
  data: {
    datoCmsProductService: {
      seoMetaTags,
      title,
      bannerText,
      bannerVideo,
      image,
      removeCampaignAnchorNav,
      modularBlocks,
      noIndex,
      popupButtonText,
      popupContactHeading,
      popupContactTestimonialQuote,
      popupContactTestimonialAuthor,
      popupContactClientLogos,
      popupContactThanksPageSlug,
    },
  },
  pageContext: { isCampaignPage },
  location,
}) => {
  const anchorLinks = modularBlocks
    .map(
      ({ navAnchorLinkText, heading }) =>
        (navAnchorLinkText || heading) && {
          text: navAnchorLinkText || heading,
          url: getAnchorId(heading),
        }
    )
    .filter(modularBlock => modularBlock !== undefined);

  const { setPopupContactActive } = useContext(SiteContext);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (isCampaignPage && searchParams.get('utm_medium') === 'Remarketing') {
      setTimeout(() => {
        setPopupContactActive(true);
      }, 5000);
    }
  }, [location.search, isCampaignPage]);

  return (
    <Layout
      noIndex={noIndex}
      isCampaignPage={isCampaignPage}
      anchorLinks={isCampaignPage && anchorLinks}
      removeCampaignAnchorNav={removeCampaignAnchorNav}
      popupContactOverrides={{
        heading: popupContactHeading,
        testimonialQuote: popupContactTestimonialQuote,
        testimonialAuthor: popupContactTestimonialAuthor,
        clientLogos: popupContactClientLogos,
        thanksPageSlug: popupContactThanksPageSlug,
      }}
      buttonText={popupButtonText}
    >
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <MediaBanner
          heading={title}
          text={bannerText}
          image={image}
          video={bannerVideo}
          isCampaignPage={isCampaignPage}
          buttonText={popupButtonText}
        />
        <ModularBlocks
          items={modularBlocks}
          isCampaignPage={isCampaignPage}
          buttonText={popupButtonText}
        />
      </main>
    </Layout>
  );
};

export const ProductServicePageTemplateQuery = graphql`
  query ProductServicePageTemplateQuery($id: String!) {
    datoCmsProductService(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerText
      bannerVideo {
        video {
          mp4Url
        }
      }
      image {
        url
        alt
        format
        fixed(
          height: 380
          width: 370
          imgixParams: { auto: "compress", fit: "crop", h: "380", w: "370" }
        ) {
          ...GatsbyDatoCmsFixed
        }
      }
      removeCampaignAnchorNav
      modularBlocks {
        ...BestModuleCalculatorModularBlockFragment
        ...CaseStudiesModularBlockFragment
        ...ContactCallToActionModularBlockFragment
        ...ContentModularBlockFragment
        ...DemoCallToActionModularBlockFragment
        ...DemoFormModularBlockFragment
        ...DotsDividerModularBlockFragment
        ...DownloadFormModularBlockFragment
        ...FeaturedLogosModularBlockFragment
        ...FeaturedStepsModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...GartnerContentModularBlockFragment
        ...IconCallToActionsModularBlockFragment
        ...ImageLargeModularBlockFragment
        ...ImageContentModularBlockFragment
        ...ImageModularBlockFragment
        ...MiniCaseStudiesModularBlockFragment
        ...MoleculesDividerModularBlockFragment
        ...NewsInsightsModularBlockFragment
        ...PopupContactCallToActionModularBlockFragment
        ...PricingPlansModularBlockFragment
        ...ProductsServicesModularBlockFragment
        ...StatisticsModularBlockFragment
        ...TeamProfilesModularBlockFragment
        ...TestimonialModularBlockFragment
        ...TestimonialsModularBlockFragment
        ...TestimonialsSliderModularBlockFragment
        ...VideoContentModularBlockFragment
        ...VideoModularBlockFragment
        ...VideosModularBlockFragment
      }
      noIndex
      popupButtonText
      popupContactHeading
      popupContactTestimonialQuote
      popupContactTestimonialAuthor
      popupContactClientLogos {
        url
        alt
      }
      popupContactThanksPageSlug
    }
  }
`;

export default ProductServicePageTemplate;
